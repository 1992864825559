export const convertToNonDecimal = (score) => {
	let numericScore;

	// Check if the score is a fraction
	if (score.includes("/")) {
		const [numerator, denominator] = score.split("/").map(Number);

		// Validate inputs
		if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
			console.error("Invalid fraction value:", score);
			return "N/A";
		}

		// Convert fraction to percentage
		numericScore = (numerator / denominator) * 100;
	} else if (Number.isInteger(score)) {
		 numericScore = score
	} else {
		// Handle percentage string
		numericScore = parseFloat(score.replace("%", ""));

		if (isNaN(numericScore)) {
			console.error("Invalid score value:", score);
			return "N/A";
		}
	}

	// Always return an integer percentage
	return `${Math.round(numericScore)}%`;
};