import React from "react";
import styled, { useTheme } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { Paragraph } from "../Paragraph";
import { H1, H4 } from "../Headers";
import percentIcon from "../../assets/percent-02.svg";
import { Button } from "../Button";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import backgroundImage2 from "../../assets/percent-02.svg";
import {
	ExcellentScoreIcon,
	FeedbackLightIcon,
	GoodScoreIcon,
	LowScoreIcon,
	VeryGoodScoreIcon,
} from "../Icons/SvgIcons";
import { convertToNonDecimal } from "../../utils/convertScoreValues";


const MainContainer = styled.div`
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 50;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.theme.colors.neutral.grey900}e6;
`;

const FeedbackContainer = styled.div`
	width: 100%;
	max-width: 743px;
	max-height: 695px;
	height: auto;
	margin-block: 2rem;
	padding-top: 48px;
	gap: 10px;
	border-radius: 16px 0 16px 16px;
	z-index: 15;
	overflow: hidden;
	position: relative;
	background: ${(props) => props.theme.colors.neutral.grey00};
	${"" /* box-shadow: ${(props) => props.theme.shadow.md}; */}

	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		border-style: solid;
		border-radius: 0 0 0 16px;
		border-width: 1.8rem;
		background: #11182700;
		${"" /* z-index: 140; */}
		border-color: ${(props) => props.color} ${(props) => props.color} ${(
			props
		) => props.theme.colors.neutral.grey100} ${(props) =>
			props.theme.colors.neutral.grey100};
		box-shadow: -1px 1px 4px 0px #0000001a,
			/* Light shadow */ -5px 5px 7px 0px #00000017,
			/* Medium light shadow */ -12px 11px 10px 0px #0000000d,
			/* Medium shadow */ -22px 19px 12px 0px #00000003,
			/* Light shadow */ -34px 29px 13px 0px #00000000; /* No shadow */
	}
`;

const FeedbackBox = styled.div`
	height: auto;
	max-height: 500px;
	padding-inline: 48px;
	padding-bottom: 10px;
	overflow-y: scroll;
`;

const ScoreContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 12px 12px 20px 20px;
	margin-bottom: 32px;
	background: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid #f3f4f6;
`;

const InnerScoreContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	position: relative;

	&::before {
		content: "";
		display: block;
		width: 209px;
		height: 209px;
		object-fit: contain;
		background: url(${backgroundImage2}) no-repeat center center;
		position: absolute;
		bottom: 0;
		right: 10px;
		z-index: 1; // Ensure the pseudo-element is behind the content
		border-radius: inherit;
	}

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		${"" /* width: 100%; */}
	}
`;

const ScoreTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 4px 12px 4px 4px;
	gap: 10px;
	border-radius: 100px;
	margin-bottom: 46px;
	align-self: start;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	background: ${(props) => props.theme.colors.neutral.grey00};
`;

const ScoreBox = styled.div`
	display: flex;
	gap: 21px;
`;

const IconBox = styled.div`
	align-self: end;
`;

const LockImage = styled.img`
	width: 12px;
	height: 12px;
	padding: 4px;
	border-radius: 32px;
	background: ${(props) => props.theme.colors.neutral.grey300};
	box-shadow: 0px 4px 4px 0px #ffffff, 0px -2px 2px 0px #f3f4f6 inset;
`;

const SummaryBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 24px;
	border-radius: 16px;
	border: 1px solid #f3f4f6f0;
	margin-top: 20px;
	background: ${(props) => props.theme.colors.neutral.grey50};
`;

const ActionableFeedback = styled.div`
	padding: 24px;
	border-radius: 16px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
`;

const TitleBox = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 24px;
`;

const ButtonConatiner = styled.div`
	display: flex;
	gap: 24px;
	padding: 26px 48px;
	border-top: 1px solid ${(props) => props.theme.colors.neutral.grey100};
`;

const RetakeButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	background: ${(props) => props.theme.colors.neutral.grey00};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey600};
		border: 1px solid ${(props) => props.theme.colors.neutral.grey50};
		background: ${(props) => props.theme.colors.neutral.grey50};
	}
`;

const DoneButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	color: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.secondary.default};
	background: ${(props) => props.theme.colors.secondary.default};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey00};
		border: 1px solid ${(props) => props.theme.colors.secondary.green200};
		background: ${(props) => props.theme.colors.secondary.green200};
	}
`;

const MarkdownText = styled(Markdown)`
	line-height: 24px;
	color: ${(props) => props.theme.colors.neutral.default};
`;

const MarkdownUlList = styled.ul`
	list-style-type: disc;
	padding-inline-start: 22px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const MarkdownOlList = styled.ol`
	padding-inline-start: 22px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const MarkdownListItem = styled.li`
  
`;

export default function Feedback({
	feedbackData,
	setShowFeedback,
	handleRestartCall
}) {
	const theme = useTheme();
	const location = useLocation()
	const navigate = useNavigate();
	const { score, quick_summary, actionable_feedback } = feedbackData;
	// console.log("feedbackData", feedbackData);

	const scores = convertToNonDecimal(score);
	const formattedScore = parseFloat(scores.replace("%", ""));
	
	const handleDone = () => {
		setShowFeedback(false);
		navigate("/dashboard/tracking")
	}

	return (
		<MainContainer>
			<FeedbackContainer
				color={
					location.pathname === "/dashboard/tracking"
						? `${theme.colors.neutral.grey900}e6`
						: theme.colors.neutral.grey900
				}
			>
				<FeedbackBox>
					<H4
						fontSize="sm"
						color="grey700"
						fontWeight={900}
						style={{ paddingBottom: "32px" }}
					>
						Feedback Report
					</H4>
					<ScoreContainer>
						<InnerScoreContainer>
							<ScoreTitle>
								<LockImage src={percentIcon} alt="" />
								<Paragraph color="grey500" fontSize="sm" fontWeight={500}>
									Your Score
								</Paragraph>
							</ScoreTitle>

							<H4 color="grey400" fontSize="xs" fontWeight={600}>
								Overall Score
							</H4>
							<ScoreBox>
								<H1
									color="grey700"
									fontWeight={800}
									style={{
										fontSize: "96px",
										lineHeight: "90px",
										paddingTop: "8px",
									}}
								>
									{convertToNonDecimal(score)}
								</H1>
								<IconBox>
									{formattedScore < 41 ? (
										<LowScoreIcon />
									) : formattedScore < 61 ? (
										<GoodScoreIcon />
									) : formattedScore < 81 ? (
										<VeryGoodScoreIcon />
									) : (
										<ExcellentScoreIcon />
									)}
								</IconBox>
							</ScoreBox>
						</InnerScoreContainer>

						<SummaryBox>
							<Paragraph color="grey700" fontSize="lg" fontWeight={600}>
								Summary
							</Paragraph>
							<Paragraph color="grey400" fontSize="md" fontWeight={700}>
								{quick_summary}
							</Paragraph>
						</SummaryBox>
					</ScoreContainer>

					<ActionableFeedback>
						<TitleBox>
							<FeedbackLightIcon color={theme.colors.warning.default} />
							<H4 color="grey700" fontSize="xs" fontWeight={700}>
								Actionable Feedback Points{" "}
							</H4>
						</TitleBox>

						<MarkdownText
							remarkPlugins={[remarkGfm]}
							components={{
								ul: MarkdownUlList, // Apply custom styles to <ul>
								ol: MarkdownOlList, // Apply custom styles to <ol>
								li: MarkdownListItem, // Apply custom styles to <li>
							}}
						>
							{actionable_feedback}
						</MarkdownText>
					</ActionableFeedback>

					{/* <p>{item.performance_tag}</p> */}
				</FeedbackBox>

				<ButtonConatiner>
					{location.pathname === "/simulate/in-call" && (
						<RetakeButton onClick={handleRestartCall}>
							Retake Simulation
						</RetakeButton>
					)}

					<DoneButton onClick={handleDone}>Done</DoneButton>
				</ButtonConatiner>
			</FeedbackContainer>
		</MainContainer>
	);
}
